import React, { useContext, useEffect, useState } from "react";
import { FlatList, Text, TouchableOpacity, View } from "react-native";
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import { getDocs, doc, getFirestore, query, collection, where } from 'firebase/firestore';
import { useNavigation, useRoute } from '@react-navigation/native';

import db, { getData } from '../../config/Firebase';
import { EventContext } from "../../context/Event.context";
import IClub from "../../interfaces/Club.interface";
import Page from '../../components/Page';
import RoundButton from '../../components/RoundButton';
import List from "../../components/List";
import styles from './style';
import { GetClubs } from "../../services/Club.service";
import { SortClubByName } from "../../services/Utils.service";


export default function Clubs() {
  const eventContext = useContext(EventContext);
  const navigation = useNavigation<any>();
  const [clubs, setClubs] = useState<IClub[]>();

  const conferenceId = eventContext.event?.conference ?? '';

  useEffect(() => {
    async function run() {
      let list = await GetClubs(conferenceId);
      setClubs(SortClubByName(list));
    }
    run();
  }, [])

  function butonClick() {
    navigation.navigate("AddClub", { conference: conferenceId, })
  }

  function empty() {
    if (clubs && clubs.length == 0) {
      return (<Text style={styles.empty}>Não existem clubes cadastrados.</Text>)
    }
  }

  return (
    <Page title="Clubes">
      <Text>Qtd: {clubs ? clubs?.length : 0}</Text>
      <View style={styles.list}>
        <List data={clubs} pageRedirect="QrCode" icon="trash" />
        {empty()}
      </View>

      <Text style={styles.total}>Total: {clubs?.length ?? 0} clubes</Text>

    </Page>
  )
}
