import React, { createContext, useEffect, useState } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import GetEvent from "../services/Event.service";
import IEvent from "../interfaces/Event.interface";
import IClub from "../interfaces/Club.interface";
import { GetClubs } from "../services/Club.service";
import { SortClubByName } from "../services/Utils.service";

const KEY = 'EVENT';

interface EventContextData {
  event?: IEvent,
  clubs: IClub[],
  IsSelected: () => boolean,
  SelectEvent: (eventId: string) => Promise<void>,
  CleanEvent: () => Promise<void>,
}

export const EventContext = createContext<EventContextData>({
  event: {} as IEvent,
  clubs: [] as IClub[],
  IsSelected: (): boolean => { return false },
  SelectEvent: async (eventId: string) => { },
  CleanEvent: async () => { },
} as EventContextData);


export default function EventProvider({ children }: any) {
  const [event, setEvent] = useState<IEvent>();
  const [clubs, setClubs] = useState<IClub[]>([]);

  useEffect(() => {
    AsyncStorage.getItem('EVENT').then((value) => {
      if (value) {
        console.log("Value:", value);
        let e = JSON.parse(value);
        setEvent(e);
        GetClubs(e.conference).then((list) => {
          setClubs(SortClubByName(list));
        })
      }
    });
  }, [])

  function IsSelected(): boolean {
    return !!event;
  }

  async function SelectEvent(eventId: string) {
    const e = await GetEvent(eventId);
    if (e) {
      setEvent(e);
      await AsyncStorage.setItem(KEY, JSON.stringify(e));
    }
  }

  async function CleanEvent() {
    setEvent(undefined);
    setClubs([]);
    await AsyncStorage.removeItem(KEY);
  }

  const data: EventContextData = {
    event,
    clubs,
    IsSelected,
    SelectEvent,
    CleanEvent,
  }

  return (
    <EventContext.Provider value={data} >
      {children}
    </EventContext.Provider>
  );
};
