import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";

import { GetClub } from "../services/Club.service";
import IClub from "../interfaces/Club.interface";
import Logo from "../components/Logo";
import colors from "../config/colors";

export default function ValidateScreen({ route }: any) {
  const [club, setClub] = useState<IClub>({ id: '', name: '', region: '', conference: '' });
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(true);

  console.log("Props: ", route.params);
  const clubId = route.params?.id;
  const conferenceId = route.params?.campo;

  useEffect((): void => {
    if (!clubId || !conferenceId) { return; };
    GetClub(clubId, conferenceId).then((data) => {
      setLoading(false);
      if (data) {
        data.conference = conferenceId;
        setClub(data);
        setValid(true);
      } else {
        alert("Clube não encontrado");
        setValid(false);
      }
    })
  }, []);

  function getValidStyle() {
    if (valid) {
      return styles.success;
    } else {
      return styles.failure;
    }
  }

  function getValidText() {
    if (loading) { return "Carregando..." }
    if (valid) {
      return "Clube validado!"
    } else {
      return "Clube inválido!"
    }
  }

  return (
    <View style={styles.view}>
      <View style={styles.viewForm}>
        <Logo />
        <View style={styles.box}>
          <View style={styles.status}>
            <Text style={getValidStyle()}>{getValidText()}</Text>
          </View>
          <View style={styles.items}>

            <View style={styles.item}>
              <Text style={styles.title}>Id: </Text>
              <Text style={styles.value}>{club.id}</Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.title}>Nome: </Text>
              <Text style={styles.value}>{club.name}</Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.title}>Região: </Text>
              <Text style={styles.value}>{club.region}</Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.title}>Associação: </Text>
              <Text style={styles.value}>{club.conference}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.greyD,
  },
  viewForm: {
    marginBottom: 150,
    width: "80%",
    maxWidth: 500,
  },
  box: {
    backgroundColor: colors.white,
    borderRadius: 20,
    padding: 20,
    justifyContent: "center",
  },
  status: {
    textAlign: "center",
    padding: 10,
    borderColor: colors.black,
    //borderWidth: 1,
  },
  success: {
    fontSize: 30,
    fontWeight: "bold",
    color: colors.success,

  },
  failure: {
    fontSize: 30,
    fontWeight: "bold",
    color: colors.failure,
  },
  items: {
    //backgroundColor: colors.red,
    padding: 10
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
  },
  title: {
    textAlign: "right",
    fontSize: 20
  },
  value: {
    textAlign: "left",
    fontSize: 25,
    fontWeight: "bold",

  },
});

