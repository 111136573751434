import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

import colors from "../config/colors";

export default function BigButton(props) {
  return (
    <TouchableOpacity
      style={styles.button}
      onPress={props.action}>
      <Text style={styles.logo}>
        {props.children}
      </Text>
      <Text style={styles.text}>{props.text}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    width: 150,
    height: 150,
    margin: 10,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primary
  },
  logo: {
    color: colors.white,
  },
  text: {
    paddingTop: 10,
    color: colors.white,
    fontSize: 20,
    textAlign: "center"
  },
});

