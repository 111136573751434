import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import db from "../config/Firebase";
import IEvent from "../interfaces/Event.interface";

const COLLECTION = 'events';

export default async function GetEvent(eventId: string): Promise<IEvent | null> {
  if (!eventId) { return null }
  return await getEventById(eventId);
}

async function getEventById(eventId: string): Promise<IEvent | null> {
  let docRef = doc(db, COLLECTION, eventId);
  let docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    console.log("Event not found!");
    return null;
  }

  let data = docSnap.data();
  return ({
    id: docSnap.id,
    title: data.title,
    conference: data.conference
  } as IEvent);
}

export async function GetEvents(): Promise<IEvent[]> {
  return await getAllEvents();
}

async function getAllEvents(): Promise<IEvent[]> {
  let colRef = collection(db, COLLECTION);
  let colSnap = await getDocs(query(colRef));

  let list: IEvent[] = [];
  colSnap.forEach((doc) => {
    list.push({ id: doc.id, ...doc.data() } as IEvent);
  });
  return list;
}
