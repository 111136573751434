import { doc, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import db from '../../config/Firebase';

import { collection, query, getDocs } from "firebase/firestore";
import IClub from "../../interfaces/Club.interface";

import styles from './style';
import ITask from "../../interfaces/task.interface";
import IReview from "../../interfaces/Review.interface";
import { EventContext } from "../../context/Event.context";

export default function Exports() {
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [conferences, setConferences] = useState([]);
  const [events, setEvents] = useState([]);
  const [clubs, setClubs] = useState<IClub[]>([]);
  const [users, setUsers] = useState([]);
  const [reviews, setReviews] = useState<IReview[]>([]);
  const eventContext = useContext(EventContext);

  const conference = eventContext.event!.conference;
  const eventId = eventContext.event!.id; // ARS
  //const eventId = "vYVfYIvhHNAqCnn9favt"; // ARJ

  useEffect(() => {
    async function getAll() {
      tasks.length > 0 ? '' : setTasks(await getTasks(eventId));
      // conferences.length > 0 ? '' : setConferences(await getData("conferences"));
      // events.length > 0 ? '' : setEvents(await getData("events"));
      clubs.length > 0 ? '' : setClubs(await getClubs(conference));
      // users.length > 0 ? '' : setUsers(await getData("users"));
      reviews.length > 0 ? '' : setReviews(await getReviews(eventId));
    }
    getAll();
  }, [])

  return (
    <View style={styles.container}>

      <Text>Export Page</Text>

      <Text>Tasks - {tasks.length} registros</Text>
      <Text>{JSON.stringify(tasks)}</Text>

      <Text>Conferences - {conferences.length} registros</Text>
      <Text>{JSON.stringify(conferences)}</Text>

      <Text>Events - {events.length} registros</Text>
      <Text>{JSON.stringify(events)}</Text>

      <Text>Clubs - {clubs.length} registros</Text>
      <Text>{JSON.stringify(clubs)}</Text>

      <Text>Users - {users.length} registros</Text>
      <Text>{JSON.stringify(users)}</Text>

      <Text>Avaliações - {reviews.length} registros</Text>
      <Text>{JSON.stringify(reviews)}</Text>
    </View>
  )
}

async function getData(collectionName: string) {
  let snapshot = await getDocs(query(collection(db, collectionName)));
  let list: any = [];
  snapshot.forEach((doc) => {
    list.push({ id: doc.id, ...doc.data() });
  });
  return list;
}

async function getClubs(conferenceId: string): Promise<IClub[]> {
  let docRefConf = doc(db, 'conferences', conferenceId);
  let colRef = collection(docRefConf, 'clubs');
  let colSnap = await getDocs(query(colRef));

  let list: IClub[] = [];
  colSnap.forEach((doc) => {
    list.push({ id: doc.id, ...doc.data() } as IClub);
  });
  return list;
}

async function getReviews(eventId: string): Promise<IReview[]> {
  console.log("EventId", eventId)
  let tasks = await getTasks(eventId);
  let split: any[] = []

  while (tasks.length > 0) {
    console.log("splice!");
    split.push(tasks.splice(0, 10).map(t => t.id));
  }

  let col = collection(db, 'reviews');
  let list: any[] = [];

  for (let index = 0; index < split.length; index++) {
    let snapshot = await getDocs(query(col, where("taskId", 'in', split[index])))
    console.log("snapshot", snapshot);
    snapshot.forEach((doc) => {
      let obj = { id: doc.id, ...doc.data() };
      //console.log(obj);
      list.push(obj);
    });
    console.log("Split finish", index);
  }

  console.log("list", list);
  return list;
}

async function getTasks(eventId: string) {
  let col = collection(db, 'tasks');
  let snapshot = await getDocs(query(col, where("eventId", '==', eventId)));
  let list: any[] = [];
  snapshot.forEach((doc) => {
    list.push({ id: doc.id, ...doc.data() });
  });
  return list as ITask[];
}

