import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { FontAwesome5 } from "@expo/vector-icons";

import colors from "../config/colors";
import RoundButton from './RoundButton';

export default function LogoutButton(props) {

  return (
    <View style={styles.bottomMenu}>
      <RoundButton action={props.action}>
        <FontAwesome5 name="sign-out-alt" size={23} color={colors.white} />
      </RoundButton>
    </View>
  );
}

const styles = StyleSheet.create({
  bottomMenu: {
    alignContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    margin: 20,
  },
});
