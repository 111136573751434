import { StyleSheet } from "react-native";
import colors from "../../config/colors";

const styles = StyleSheet.create({
  view: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.greyD,
  },
  viewForm: {
    marginBottom: 150,
    width:"80%",
    maxWidth: 500,
  },
  image: {
    alignSelf: "center",
    width: 200,
    height: 200,
  },
  input: {
    marginVertical: 7,
    paddingVertical: 10,
    paddingHorizontal: 10,
    height: 50,
    backgroundColor: colors.white,
    color: colors.black,
    borderRadius: 10,
  },
  button: {
    marginTop: 15,
    backgroundColor: colors.primary,
    textAlign: "center",
    justifyContent: "center",
    borderRadius: 10,
    height: 50,
  },
  buttonText: {
    color: colors.white,
    fontSize: 20,
    
  }
});

export default styles;