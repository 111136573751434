import React, { useContext } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { options } from '../App';
import { EventContext } from '../context/Event.context';
import AppRoute from './App.routes';

import EventSelection from '../screens/Event/Selection.screen';

const EventRoute: React.FC = () => {
  const EventStack = createStackNavigator();
  const eventContext = useContext(EventContext);

  return (
    <>
      {eventContext.IsSelected() ?
        <AppRoute />
        :
        <>
          <EventStack.Navigator screenOptions={options}>
            <EventStack.Screen name='EventSelection' component={EventSelection} options={{ title: "Seleção de Evento" }} />
          </EventStack.Navigator>
        </>
      }
    </>
  )
};

export default EventRoute;
