import React, { useEffect, useState } from "react";
import { FlatList, Text, TouchableOpacity, View } from "react-native";
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import { getDocs, getFirestore, query, collection } from 'firebase/firestore';
import { useNavigation, useRoute } from '@react-navigation/native';

import db, { getData } from '../../config/Firebase';
import Page from '../../components/Page';
import RoundButton from '../../components/RoundButton';
import styles from './style';
import List from "../../components/List";


export default function AddClub() {
  const navigation = useNavigation();

  return (
    <Page title="Cadastrar Clube">
      <View style={styles.list}>
        <Text style={styles.empty}>Não existem clubes cadastrados.</Text>
      </View>
    </Page>
  )
}
