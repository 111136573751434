import React, { useContext, useEffect, useState } from "react";
import { Alert, FlatList, Platform, StyleSheet, Text, ToastAndroid, TouchableOpacity, View } from "react-native";
import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

import colors from "../../config/colors";
import { EventContext } from "../../context/Event.context";
import Page from '../../components/Page';
import { GetRankingExtract, DeleteReview } from "../../services/Ranking.service";
import IRanking from "../../interfaces/Ranking.interface";


export default function RankingDetailed() {
  const eventContext = useContext(EventContext);
  const [ranking, setRanking] = useState<IRanking[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [detailed, setDetailed] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    GetRankingExtract(eventContext.event!).then((value) => {
      console.log("Ranking:", value);
      setRanking(value.sort((a: any, b: any) => (a.Pontos > b.Pontos) ? 1 : (a.Pontos < b.Pontos) ? -1 : 0));
      setIsLoading(false);
    });
  }, [])

  function empty() {
    if (ranking && ranking.length == 0) {
      return (<Text style={styles.empty}>Não existe Ranking.</Text>)
    }
  }

  function loading() {
    if (isLoading) {
      return (<Text style={styles.empty}>Carregando...</Text>)
    }
  }

  function deleteReview(reviewId: string) {
    console.log("Alert", reviewId);

    try {
      DeleteReview(reviewId);

      if(Platform.OS === "android"){
        ToastAndroid.show("Atividade apagada", ToastAndroid.SHORT);
      }

      let list: IRanking[] = []
      ranking.forEach(r => {
        let revList = r.reviews.filter(x => x.id != reviewId);
        r.reviews = revList;
        let points = 0;
        revList.forEach(x => x.value == 0 ? points += x.ratio : points += x.value);
        list.push(r);
      });
      setRanking(list);
    } catch (error) {
      
    }
  }

  return (
    <Page title="Clubes">
      <View style={styles.list}>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={ranking}
          renderItem={
            ({ item }) =>
              <View style={styles.view}>
                <Text style={styles.item}>
                  <View style={styles.head}>
                    <Text>{item.club.conference ?? ""} - {item.club.name ?? ""} - {item.club.id} - Total: {item.points}</Text>
                  </View>

                  {item.reviews.sort((a, b) => (a.task!.title > b.task!.title) ? 1 : (a.task!.title < b.task!.title) ? -1 : 0).map((rev) => (
                    <View style={styles.subView}>
                      <Text style={styles.subItem}>Id: {rev.id}</Text>
                      <Text style={styles.subItem}>Email: {rev.email}</Text>
                      <Text style={styles.subItem}>Task: {rev.task!.title ?? ""} - {rev.task!.points ?? 0} pontos</Text>
                      <Text style={styles.subItem}>%: {rev.ratio} ou Ponto Manual: {rev.value ?? 0}</Text>
                      <Text style={styles.subItem}>Total: {rev.ratio / 100 * (rev.task!.points ?? 0)}</Text>
                      <Text style={styles.subItem}>Timestamp: {rev.timestamp}</Text>
                      <TouchableOpacity
                        style={styles.buttonDel}
                        onLongPress={() => { deleteReview(rev.id!); }}>
                        <Text style={styles.logo}>
                          <FontAwesome5 name="trash" size={25} color="white" />
                        </Text>
                        {/* <Text style={styles.text}>Excluir</Text> */}
                      </TouchableOpacity>
                    </View>
                  ))}
                </Text>
              </View>
          }
          keyExtractor={(item, index) => item.club.id!}
        />
        {isLoading ? loading() : empty()}
      </View>

      <Text style={styles.total}>Total: {ranking?.length ?? 0} clubes</Text>

    </Page>
  )
}

const styles = StyleSheet.create({
  view: {
    width: 'auto',
    position: 'relative',
    flexDirection: "row",
    alignSelf: 'stretch',
    justifyContent: "flex-start",
    margin: 5
  },
  head: {
    width: "100%",
    position: 'relative',
    margin: 5
  },
  item: {
    width: '100%',
    backgroundColor: colors.grey,
    padding: 12,
    paddingHorizontal: 20,
    borderRadius: 50,
    marginBottom: 5,
    marginRight: 10
  },
  subView: {
    width: 'auto',
    alignSelf: 'stretch',
    margin: 5,
    backgroundColor: colors.greyD
  },
  subItem: {
    alignContent: "flex-start",
    flex: 1,

  },
  button: {
    alignContent: "flex-start",
    backgroundColor: "red",
    padding: 12,
    paddingHorizontal: 15,
    borderRadius: 50,
    marginBottom: 5,
    marginRight: 10

  },

  list: {
    margin: 30,
  },
  total: {
    alignSelf: 'center',
  },
  empty: {
    alignSelf: 'center',
    color: colors.red,
  },
  bottomMenu: {
    flex: 1,
    alignSelf: "flex-end",
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    margin: 20,
  },


  buttonDel: {
    width: 50,
    height: 50,
    margin: 5,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.red
  },
  logo: {
    color: colors.white,
  },
  text: {
    paddingTop: 10,
    color: colors.white,
    fontSize: 20,
    textAlign: "center"
  },
});

