import React, { useEffect, useRef, useState } from 'react';
import { Text, View } from 'react-native';
import QRCodeStyling from "qr-code-styling";

import Template, { IQrCodeOptions } from '../services/Template';

export default function QrCode(props: any) {
  let [qrCode, setQrCode] = useState<QRCodeStyling>();
  const [rendered, setRendered] = useState(false);
  const [template, setTemplate] = useState<any>();
  const ref = useRef(null);

  console.log("Data: ", props.data);

  if (!template) {
    let options: IQrCodeOptions = {
      width: props.size,
      height: props.size,
      data: props.data,
      margin: props.margin,
    }
    setTemplate(Template(options));
  }

  useEffect(() => {
    if (props.data) {
      console.log("Rendered: ", rendered);

      if (ref.current && !rendered) {
        if (!qrCode) {
          setQrCode(new QRCodeStyling(template));
        }

        if (qrCode) {
          qrCode.append(ref.current);
          setRendered(true);
          //qrCode.download({ name: props.text, extension: "svg" });
        }
      }
    }
  }, [qrCode, ref]);

  if (props.data) {
    return (
      <>
        <View ref={ref}></View>
      </>
    )
  } else {
    return (<></>)
  }


}
