import React, { useContext } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

import styles from './style';
import Page from '../../components/Page';
import BigButton from '../../components/BigButton';
import LogoutButton from '../../components/LogoutButton';
import { EventContext } from '../../context/Event.context';
import { saveTasksToCloud, saveClubsToCloud } from '../../config/Firebase';

export default function Home() {
  const navigation = useNavigation<any>();
  const { CleanEvent } = useContext(EventContext);

  return (
    <Page title="Página Inicial">
      <View style={styles.bigMenu}>
        <BigButton action={() => navigation.navigate("Clubs")} text="Clubes">
          <MaterialCommunityIcons name="account-group" size={60} color="white" />
        </BigButton>
        <BigButton action={() => navigation.navigate("Tasks")} text="Atividades">
          <MaterialCommunityIcons name="format-list-bulleted" size={60} color="white" />
        </BigButton>
        <BigButton action={() => navigation.navigate("Users")} text="Usuários">
          <MaterialCommunityIcons name="account-hard-hat" size={60} color="white" />
        </BigButton>
        <BigButton action={() => navigation.navigate("Exports")} text="Exportar">
          <MaterialCommunityIcons name="code-json" size={60} color="white" />
        </BigButton>
        {/*
        <BigButton action={() => saveClubsToCloud()} text="Enviar Clubes para nuvem">
          <MaterialCommunityIcons name="cloud-upload" size={60} color="white" />
        </BigButton>
        <BigButton action={() => saveTasksToCloud()} text="Enviar Tasks para nuvem">
          <MaterialCommunityIcons name="cloud-upload" size={60} color="white" />
        </BigButton>
        */}
        <BigButton action={() => navigation.navigate("Print")} text="Imprimir QrCodes">
          <MaterialCommunityIcons name="printer" size={60} color="white" />
        </BigButton>
        <BigButton action={() => navigation.navigate("RankingSummarized")} text="Calcular o Ranking">
          <MaterialIcons name="calculate" size={60} color="white" />
        </BigButton>
        <BigButton action={() => navigation.navigate("RankingDetailed")} text="Extrato do Ranking">
          <MaterialIcons name="format-list-bulleted" size={60} color="white" />
        </BigButton>
        <BigButton action={() => navigation.navigate("ClubsByTasks")} text="Clubes por Atividades">
          <MaterialIcons name="format-list-bulleted" size={60} color="white" />
        </BigButton>
      </View>
      <LogoutButton action={() => { CleanEvent() }} />
    </Page >
  )
}
