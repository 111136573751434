import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import colors from "../config/colors";

export default function RoundButton(props) {

  return (
    <TouchableOpacity style={styles.roundButton}
      onPress={props.action}>
      {props.children}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  roundButton: {
    width: 60,
    height: 60,
    bottom: 30,
    borderRadius: 50,
    margin: 5,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primary
  },
});

