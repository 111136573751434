import { createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail } from "firebase/auth";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore";
import db from "../config/Firebase";
import IUser from "../interfaces/user.interface";


export async function GetUser(userId: string): Promise<IUser | null> {
  if (!userId) { return null; }
  return await getUserByUserId(userId);
}

export async function GetUsers(conferenceId: string): Promise<IUser[]> {
  if(conferenceId.length > 0){
    return await GetAllUsersByConferenceId(conferenceId);
  }else{
    return await GetAllUsers();
  }
}

export async function CreateUser(email: string, password: string, conferenceId: string){
  await register(email, password);
  await AddUser(email, conferenceId);
  //await AddConferenceToUser(email, conferenceId);
}

/*
#################
## Collections ##
#################
*/

const COLLECTION = 'users';
const SUB_COLLECTION = 'conferences';


async function getUserByUserId(userId: string): Promise<IUser | null> {
  let docRefClub = doc(db, COLLECTION, userId);
  let docSnap = await getDoc(docRefClub);

  if (!docSnap.exists()) {
    console.log("User not found!");
    return null;
  }

  let data = docSnap.data();
  return ({
    email: docSnap.id,
    password: data.password,
    conferences: data.conferences,
  } as IUser);
}

async function GetAllUsers(): Promise<IUser[]> {
  let colRef = collection(db, COLLECTION);
  let colSnap = await getDocs(query(colRef, orderBy("email")));

  let list: IUser[] = [];
  colSnap.forEach((doc) => {
    list.push({ email: doc.id, ...doc.data() } as IUser);
  });
  return list;
}

async function GetAllUsersByConferenceId(conferenceId: string): Promise<IUser[]> {
  let colRef = collection(db, COLLECTION);
  //let colSnap = await getDocs(query(colRef, where(SUB_COLLECTION,"array-contains",conferenceId)));
  let colSnap = await getDocs(query(colRef, where(SUB_COLLECTION,"array-contains",conferenceId)));

  let list: IUser[] = [];
  colSnap.forEach((doc) => {
    let d = doc.data();
    list.push({ email: doc.id, ...doc.data() } as IUser);
  });
  return list;
}

async function AddUser(email: string, conference: string) {
  console.log("CreateUser");
  if(!email) {
    throw new Error("Email is required to AddUser");
  }

  const dRef = doc(db, COLLECTION, email);
  const docSnap = await getDoc(dRef);
  let user: IUser = {
    email: email,
    conferences: []
  }
  
  if (docSnap.exists()) {
    let data = docSnap.data();
    user.conferences = data.conferences;
  }
  
  if (user.conferences.filter(x => x == conference).length == 0) {
    user.conferences.push(conference);
  }

  await setDoc(dRef, { conferences: user.conferences });

  return user;
}

// UPDATE

export async function AddConferenceToUser(email: string, conferenceId: string): Promise<IUser | undefined> {
  console.log("AddConferenceToUser");
  const dRef = doc(db, COLLECTION, email);
  const docSnap = await getDoc(dRef);

  if(!docSnap.exists()){
    return undefined;
  }

  let data = docSnap.data();
  let user: IUser = {
    email: docSnap.id,
    conferences: data.conferences ?? []
  }
  
  if(!user.conferences.includes(conferenceId)){
    user.conferences.push(conferenceId);

    await setDoc(dRef, user);
  }

  return user;
}

export async function RemoveConferenceFromUser(email: string, conferenceId: string): Promise<IUser | undefined> {
  console.log("RemoveConferenceFromUser");
  const dRef = doc(db, COLLECTION, email);
  const docSnap = await getDoc(dRef);

  if(!docSnap.exists()){
    return undefined;
  }

  let data = docSnap.data();
  let user: IUser = {
    email: docSnap.id,
    conferences: data.conferences ?? []
  }
  
  if(user.conferences.includes(conferenceId)){
    user.conferences = user.conferences.filter(x => x == conferenceId);

    await setDoc(dRef, user);
  }

  return user;
}

// DELETE
export async function DeleteUser(email: string): Promise<void> {
  if(!email){
    console.log("Email is required");
    return;
  }
  const docRef = doc(db, COLLECTION, email);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    console.log("User not found!");
    return;
  }

  let data = docSnap.data();
  let user: IUser = {
    email: docSnap.id,
    conferences: data.conferences
  }

  // if(user.conferences.length > 0){
  //   alert("Para excluir este usuário, primeiro você precisa remover todas as associações que ele tem acesso!");
  //   return;
  // }

  await deleteDoc(docRef);
}


// ResetPassword
export async function ResetPassword(email: string): Promise<void> {
  if(!email){
    console.log("Email is required");
    return;
  }

  const auth = getAuth();
  try {
    await sendPasswordResetEmail(auth, email);
    console.log("Success Reset Password");
    
  } catch (error) {
    console.log("Error reset password");
    console.log(error);
  }
}

/*
####################
## Authentication ##
####################
*/

async function register(email: string, password: string): Promise<IUser> {
  console.log("register");
  const auth = getAuth();
  let u = await createUserWithEmailAndPassword(auth, email, password);
  let user: IUser = {
    email: u.user.email ?? "",
    conferences: []
  }
  return user;
}

async function unregister(email: string): Promise<void> {
  console.log("UnRegister");
  // const auth = getAuth();
  // let u = await createUserWithEmailAndPassword(auth, email, password);
  // let user: IUser = {
  //   email: u.user.email ?? "",
  //   conferences: []
  // }
  // return;
}
