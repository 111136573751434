import React, { useContext, useEffect, useState, useRef } from 'react';
import { FlatList, Image, StyleSheet, Text, View } from 'react-native';
import QRCodeStyling from "qr-code-styling";

import colors from "../config/colors";
import { EventContext } from "../context/Event.context";
import Page from '../components/Page';
import Template from '../services/Template';
import { GenerateURL } from '../services/QrCode.service';
import QrCode from '../components/QrCode';

interface IQrCodeInfo {
  url: string,
  club: string
}

export default function SingleQrCode() {
  const eventContext = useContext(EventContext);
  const [uri, setUri] = useState("");
  const [qrCodes, setQrCodes] = useState<IQrCodeInfo[]>();
  const ref = useRef(null);

  const conferenceId = eventContext.event?.conference;

  // const qrCode = new QRCodeStyling(t);
  // let rawList: Blob[] = [];

  useEffect(() => {
    console.log("Clubs: ", eventContext.clubs);
    setQrCodes(eventContext.clubs.map((club) => {
      club.conference = conferenceId;
      let url = GenerateURL(club.id!, club.name!, club.conference!);
      let q: IQrCodeInfo = {
        url: url,
        club: club.name!
      };
      return q;
    }))
  }, []);

  // async function loadCodes() {
  //   let b = await qrCode.getRawData("png");
  //   if (b) {
  //     rawList.push(b);
  //   }
  //   if (ref && ref.current) {
  //     qrCode.append(ref.current);
  //   }
  // }

  // async function getUri() {
  //   return "data:image/png;base64," + await rawList[0].text;
  // }




  return (
    <Page title="QrCode Page" backgroundColor={colors.greyD} >
      <FlatList
        showsVerticalScrollIndicator={true}
        data={qrCodes}
        style={styles.grid}
        numColumns={3}
        renderItem={({ item }) => {
          return (
            <View style={styles.item}>
              <QrCode data={item.url} />
              <Text style={styles.txt}>{item.club}</Text>
              <Text style={styles.url}>{item.url}</Text>
            </View>
          )
        }} />
      <Text></Text>
    </Page>
  )
}

const styles = StyleSheet.create({
  grid: {
    //backgroundColor: colors.greyD,
  },
  item: {
    margin: 10,
    maxWidth: 300,
    backgroundColor: colors.white,
    color: colors.black,
  },
  txt: {
    color: colors.black,
  },
  url: {
  },
});