import React from 'react';
import { StyleSheet, View } from 'react-native';
import colors from "../config/colors";
import Title from './Title';

export default function Page(props: any) {
  function renderTitle(): boolean {
    return !!props.title;
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: props.backgroundColor ?? colors.white,
      position: "relative",
      paddingTop: 10,
      justifyContent: 'flex-start',
      color: colors.black
    }
  });

  return (
    <>
      <View style={styles.container}>
        {renderTitle() ? <Title text={props.title} /> : null}

        {props.children}
      </View>
    </>
  );
}


