import React, { useContext, useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';

import { EventContext } from '../../context/Event.context';
import { AuthContext } from '../../context/Auth.context';
import { GetEvents } from '../../services/Event.service'

import styles from './Selection.style';
import Page from '../../components/Page';
import BigButton from '../../components/BigButton';
import LogoutButton from '../../components/LogoutButton';
import IEvent from '../../interfaces/Event.interface';

export default function EventSelection() {
  const [events, setEvents] = useState<IEvent[]>([]);
  const authContext = useContext(AuthContext);
  const eventContext = useContext(EventContext);

  useEffect(() => {
    let isMounted = true;
    GetEvents().then((list) => {
      if (isMounted) {
        setEvents(list);
      }
    })
    return () => { isMounted = false };
  }, []);

  async function selectEvent(eventId: string) {
    await eventContext.SelectEvent(eventId);
  }

  return (
    <Page>
      <View style={styles.bigMenu}>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={events}
          renderItem={
            ({ item }) =>
              <BigButton action={() => selectEvent(item.id)} text={item.title}>
                {item.conference}
              </BigButton>
          }
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
      <LogoutButton action={() => { authContext.Logout() }} />
    </Page >
  )


}
