import { StyleSheet } from "react-native";
import colors from "../../config/colors";

const styles = StyleSheet.create({
  bigMenu: {
    alignContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
  },
  bottomMenu: {
    alignContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    margin: 20,
  },
  buttonDel: {
    alignContent: "flex-start",
    backgroundColor: colors.primary,
    padding: 12,
    paddingHorizontal: 15,
    borderRadius: 50,
    marginBottom: 5,
    marginRight: 10

  },

  deleteTask: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 5
  }

});

export default styles;