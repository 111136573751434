export default {
    primary: "#0E1951",
    success: "green",
    failure: "red",

    grey: "#f5f5f5",
    greyD: "#D0D0D0",
    red: "red",
    black: "black",
    white: "white"

}
