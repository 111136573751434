import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import { options } from '../App';

import LoginScreen from "../screens/Login";
import ValidateScreen from "../screens/Validate.screen";

const AuthRoute: React.FC = () => {
  const AuthStack = createStackNavigator();

  return (
    <AuthStack.Navigator screenOptions={options}>
      <AuthStack.Screen name="login" component={LoginScreen} />
      <AuthStack.Screen name="validate" component={ValidateScreen} options={{ title: "Validar QrCode" }} />
    </AuthStack.Navigator>
  )
};

export default AuthRoute;
