
export default class QrCodeService {

  constructor() {

  }
}

export function GenerateURL(id: string, clube: string, campo: string): string {
  return encodeURI(`https://ranking.camporionline.org/validate?id=${id}&clube=${clube.replace(" ", "_")}&campo=${campo}`);
}