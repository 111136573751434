import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import db from "../config/Firebase";
import IClub from "../interfaces/Club.interface";

const COLLECTION = 'clubs';

export async function GetClub(clubId: string, conferenceId: string): Promise<IClub | null> {
  if (!clubId || !conferenceId) { return null; }
  return await getClub(clubId, conferenceId);
}

async function getClub(clubId: string, conferenceId: string): Promise<IClub | null> {
  let docRefConf = doc(db, 'conferences', conferenceId);
  let docRefClub = doc(docRefConf, COLLECTION, clubId);
  let docSnap = await getDoc(docRefClub);

  if (docSnap.exists()) {
    let data = docSnap.data();
    return ({
      id: docSnap.id,
      name: data.name,
      region: data.region,
      conferenceId: conferenceId
    } as IClub);
  } else {
    console.log("Club not found!");
    return null;
  }
}

export async function GetClubs(conferenceId: string): Promise<IClub[]> {
  return await GetAllClubs(conferenceId);
}

async function GetAllClubs(conferenceId: string): Promise<IClub[]> {
  let docRefConf = doc(db, 'conferences', conferenceId);
  let colRef = collection(docRefConf, COLLECTION);
  let colSnap = await getDocs(query(colRef));

  let list: IClub[] = [];
  colSnap.forEach((doc) => {
    list.push({ id: doc.id, ...doc.data() } as IClub);
  });
  return list;
}


