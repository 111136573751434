import { StyleSheet } from "react-native";
import colors from "../../config/colors";

const styles = StyleSheet.create({
  bigMenu: {
    alignContent: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
});

export default styles;