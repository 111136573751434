import React, { useContext, useEffect, useState } from 'react';
import { FlatList, Modal, Platform, Pressable, Text, TextInput, ToastAndroid, TouchableOpacity, View } from 'react-native';
import { EvilIcons, FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { StatusBar } from 'expo-status-bar';

import styles from './style';
import { EventContext } from '../../context/Event.context';
import { AuthContext } from '../../context/Auth.context';
import AddButton from '../../components/AddButton';
import colors from '../../config/colors';
import { compareByName } from '../../services/Utils.service';
import IUser from '../../interfaces/User.interface';
import { CreateUser, GetUsers, ResetPassword } from '../../services/User.service';


export default function Users() {
  const eventContext = useContext(EventContext);
  const authContext = useContext(AuthContext);
  const conferenceId = eventContext.event?.conference ?? '';
  const eventId = eventContext.event?.id ?? '';
  const cleanUser: IUser = {email: "", conferences: [conferenceId]};
  const types = [{value: 'simple', label:"Simples"},{value:'subtasks', label: "Sub-Itens"}];

  const [users, setUsers] = useState<IUser[]>([]);
  const [user, setUser] = useState<IUser>(cleanUser);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [password, setPassword] = useState<string>("");
  const [modalVisible, setModalVisible] = useState(false);


  

  GetUsers(conferenceId).then((userList) => {
    setUsers(userList.sort((a, b) => compareByName(a.email, b.email)));
  });

  function editUser(email: string) {
    const user = users.find(x => x.email == email);
    if(user){
      setUser(user);
      openModal();
    }
  }
  
  // get entire list
  async function getUsersList() {
    let list = await GetUsers("");
    setUsers(list);
  }

  // delete
  async function resetPassword(email: string) {
    try {
      await ResetPassword(email);

      alert("Email enviado");

      if(Platform.OS === "android"){
        ToastAndroid.show("Foi enviado um email para fazer o reset da senha", ToastAndroid.SHORT);
      }
    } catch (error) { 
      
    }
    setUser(cleanUser);
    await getUsersList();
}

  // update item
  async function saveUser() {
    try {
      if(isNew){
        await CreateUser(user.email, password, conferenceId);
      }

      if(Platform.OS === "android"){
        ToastAndroid.show(`${user.email} foi salvo`, ToastAndroid.SHORT);
      }
      getUsersList();
      setModalVisible(false);
      setUser(cleanUser);
    } catch (e) {
      console.error("Error saving document: ", e);
    }
  }

  function openModal() {
    setModalVisible(true);
  }
  
  function openCleanModal() {
    setUser(cleanUser);
    setModalVisible(true);
  }


  return (
    <View style={[
      styles.container,
      {
        opacity: modalVisible ? 0.9 : 1,
        backgroundColor: modalVisible ? colors.black : colors.white
      }
    ]}>
      <View style={{
        opacity: modalVisible ? 0.1 : 1
      }}>

        <StatusBar style="light" />
        <View style={styles.infoContainer}>
          <View style={styles.containerForm}>
            <Text>Email: {user.email}</Text>
            <Text>Password: {password}</Text>
            <Text>Conferences: {user.conferences.length}</Text>
          </View>
          <Text>Quantidade: {users.length}</Text>
          <FlatList
            style={styles.containerList}
            showsVerticalScrollIndicator={false}
            data={users}
            renderItem={
              ({ item }) =>
                <View style={styles.deleteTask}>
                  <Text style={styles.itemText} onPress={() => editUser(item.email)}>{item.email}</Text>
                  <TouchableOpacity style={styles.buttonDel} onPress={() => resetPassword(item.email)}>
                    <MaterialCommunityIcons name="lock-reset" size={23} color="white" />
                  </TouchableOpacity>
                </View>
            }
            keyExtractor={(item, index) => index.toString()}
          />
        </View>

      </View>

      {/* modal for new item */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            {/* close modal button */}
            <Pressable style={{alignSelf: "flex-end",alignItems: "center",justifyContent: "center"}} onPress={()=>setModalVisible(false)}>
              <EvilIcons name="close-o" size={30} color={colors.primary} />
            </Pressable>
            {/* textinput for new item */}
            <Text>Email: </Text>
            <TextInput
              placeholder='Email'
              value={user.email}
              onChangeText={(text) => setUser({ ...user, email: text } as IUser)}
              //onSubmitEditing={addTask}
              style={styles.input}
              autoFocus
            />
            <Text>Senha: </Text>
            <TextInput
              placeholder='Senha'
              value={password}
              onChangeText={(text) => setPassword(text)}
              //onSubmitEditing={addTask}
              style={styles.input}
            />
            {/* add button */}
            <Pressable style={styles.button} onPress={saveUser}>
              <Text style={styles.buttonText}>{isNew ? "Adicionar": "Salvar"}</Text>
            </Pressable>
          </View>
          
        </View>
      </Modal>

      {/* button to open the modal */}
      <View style={styles.buttonContainer}>
        <AddButton onPress={openCleanModal} />
      </View>
    </View>
  )


}
