import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../config/Firebase";
import ILogin from "../interfaces/Login.interface";

export default async function GetToken(login: ILogin): Promise<string | null> {
  if (login.email == '' || login.password == '') {
    return null;
  }
  const response = await signInWithEmailAndPassword(auth, login.email, login.password);
  const token = await response.user.getIdToken();
  return token;
}
