import 'react-native-gesture-handler';
import React from 'react';
import { StatusBar, SafeAreaView } from 'react-native';

import AuthProvider from './context/Auth.context';
import IndexRoute from './routes/Index.routes';
import colors from './config/colors';

export const options = {
  headerStyle: { backgroundColor: colors.primary },
  headerTintColor: colors.white,
};

export default function App() {

  return (
    <>
      <StatusBar barStyle='light-content' />
      <SafeAreaView style={{ flex: 1, backgroundColor: colors.primary }}>
        <AuthProvider>
          <IndexRoute />
        </AuthProvider>
      </SafeAreaView>
    </>
  );
}

export const linking = {
  prefixes: ['rankingadmin://', 'https://ranking.camporionline.org'],
};