import React, { useContext, useState } from "react";
import { Alert, Image, Text, View } from "react-native";
import { TextInput, TouchableOpacity } from "react-native-gesture-handler";
import Logo from "../../components/Logo";

import { AuthContext } from "../../context/Auth.context";
import ILogin from "../../interfaces/Login.interface";
import styles from "./style";

export default function LoginScreen() {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [credentials, setCredentials] = useState<ILogin>({ email: '', password: '' });

  const { Login } = useContext(AuthContext);

  const submitHandler = async () => {
    //TODO validate credentials

    console.log("SubmitHandler");
    setIsAuthenticating(true);

    try {
      await Login(credentials);
    } catch (error) {
      Alert.alert(
        'Autenticação falhou!',
        'Confira as credenciais informadas e tente novamente.'
      );
      setIsAuthenticating(false);
    }
  }

  if (isAuthenticating) {
    //return <LoadingOverlay message="Autenticando..." />
  }

  return (
    <View style={styles.view}>
      <View style={styles.viewForm}>
        <Logo />
        <TextInput
          placeholder="Email"
          onChangeText={(text) => { setCredentials({ email: text, password: credentials.password }) }}
          style={styles.input}
        />
        <TextInput
          placeholder="Senha"
          secureTextEntry={true}
          onChangeText={(text) => { setCredentials({ email: credentials.email, password: text }) }}
          style={styles.input}
        />
        <TouchableOpacity style={styles.button} onPress={submitHandler}>
          <Text style={styles.buttonText}>Entrar</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
