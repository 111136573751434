import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import db from "../config/Firebase";
import ITask, { ISubtask } from "../interfaces/task.interface";

const COLLECTION = 'tasks';
const SUB_COLLECTION = 'subTasks';

export async function GetTask(taskId: string): Promise<ITask | null> {
  if (!taskId) { return null; }
  return await getTask(taskId);
}

async function getTask(taskId: string): Promise<ITask | null> {
  let docRefClub = doc(db, COLLECTION, taskId);
  let docSnap = await getDoc(docRefClub);

  if (!docSnap.exists()) {
    console.log("Task not found!");
    return null;
  }

  let data = docSnap.data();
  return ({
    id: docSnap.id,
    title: data.title,
    eventId: data.eventId,
  } as ITask);
}

export async function GetTasks(eventId: string): Promise<ITask[]> {
  let colRef = collection(db, COLLECTION);
  let colSnap = await getDocs(query(colRef, where("eventId", "==", eventId)));

  let list: ITask[] = [];
  colSnap.forEach((doc) => {
    list.push({ id: doc.id, ...doc.data() } as ITask);
  });
  return list;
}

async function GetAllTasks(): Promise<ITask[]> {
  let colRef = collection(db, COLLECTION);
  let colSnap = await getDocs(query(colRef));

  let list: ITask[] = [];
  colSnap.forEach((doc) => {
    list.push({ id: doc.id, ...doc.data() } as ITask);
  });
  return list;
}

// CREATE

export async function AddTask(task: ITask): Promise<ITask> {
    console.log("CreateTask");
    let cRef = collection(db, COLLECTION);
    let rev: ITask = {
      title: task.title,
      category: task.category,
      points: task.points ?? 0,
      manual: task.manual ?? false,
      type: task.type ?? "simple",
      eventId: task.eventId
    }
    let doc = await addDoc(cRef, rev);
    if (task.subtasks) {
      let subCol = collection(doc, SUB_COLLECTION);
      task.subtasks.forEach(async (sr) => {
        await addDoc(subCol, sr);
      });
    }
    task.id = cRef.id;
    return task;
}

// UPDATE

export async function SaveTask(task: ITask): Promise<ITask> {
    console.log("UpdateTask");
    let dRef = doc(db, COLLECTION, task.id!);
    let t: ITask = {
      title: task.title,
      category: task.category,
      points: task.points ?? 0,
      manual: task.manual ?? false,
      type: task.type ?? "simple",
      eventId: task.eventId
    }
    await setDoc(dRef, t);

    // if (task.subtasks) {
    //   task.subtasks.forEach(async (sr) => {
    //     let subDoc = doc(dRef, SUB_COLLECTION , sr.id!);
    //     let st: ISubtask = {
    //       description: sr.description,
    //       order: sr.order,
    //       points: sr.points
    //     };
    //     await setDoc(subDoc, st);
    //   });
    // }
    task.id = dRef.id;
    return task;
}

// DELETE

export function DeleteTask(taskId: string): void {
  const docRef = doc(db,COLLECTION,taskId);
  deleteDoc(docRef);
}