import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { options } from '../App';

import Home from '../screens/Home';
import Clubs from '../screens/Clubs';
import AddClub from '../screens/Clubs/Add';
import Exports from '../screens/Exports';
import SingleQrCode from '../screens/QrCode.screen';
import Print from '../screens/Print.screen';
import RankingDetailed from '../screens/Ranking/Detailed';
import RankingSummarized from '../screens/Ranking/Summarized';
import Tasks from '../screens/Tasks';
import ClubsByTasks from '../screens/Reports/ClubsByTasks';
import Users from '../screens/Users';
//import ValidateScreen from '../screens/Validate';

const AppRoutes: React.FC = () => {
  const AppStack = createStackNavigator();

  return (
    <AppStack.Navigator screenOptions={options}>
      <AppStack.Screen name='Home' component={Home} options={{ title: "Página Inicial" }} />
      <AppStack.Screen name='Clubs' component={Clubs} options={{ title: "Lista de Clubes" }} />
      <AppStack.Screen name='AddClub' component={AddClub} options={{ title: "Cadastrar Clube" }} />
      <AppStack.Screen name='Exports' component={Exports} options={{ title: "Exportar Dados" }} />
      <AppStack.Screen name='QrCode' component={SingleQrCode} options={{ title: "Gerar QrCode" }} />
      <AppStack.Screen name='Print' component={Print} options={{ headerShown: false }} />
      <AppStack.Screen name='RankingSummarized' component={RankingSummarized} options={{ title: "Ranking Resumido" }} />
      <AppStack.Screen name='RankingDetailed' component={RankingDetailed} options={{ title: "Ranking Detalhado" }} />
      <AppStack.Screen name='Tasks' component={Tasks} options={{ title: "Lista de Atividades" }} />
      <AppStack.Screen name='ClubsByTasks' component={ClubsByTasks} options={{ title: "clubes por Atividades" }} />
      <AppStack.Screen name='Users' component={Users} options={{ title: "Usuários" }} />

      {/*<AppStack.Screen name="validate" component={ValidateScreen} options={{ title: "Validar QrCode" }} />*/}
    </AppStack.Navigator>
  )
};

export default AppRoutes;
