import { StyleSheet } from "react-native";
import colors from "../../config/colors";

const styles = StyleSheet.create({
  list: {
    margin: 30,
  },
  total: {
    alignSelf: 'center',
  },
  empty: {
    alignSelf: 'center',
    color: colors.red,
  },
  bottomMenu: {
    flex: 1,
    alignSelf: "flex-end",
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    margin: 20,
  },
});

export default styles;