import { StyleSheet } from "react-native";
import colors from "../../config/colors";

const styles = StyleSheet.create({
  view: {
    //width: 'auto',
    position: 'relative',
    flexDirection: "column",
    //alignSelf: 'stretch',
    justifyContent: "flex-start",
    margin: 5
  },
  head: {
    width: "100%",
    position: 'relative',
    margin: 5,
    fontWeight: "bold",
  },
  item: {
    //width: '100%',
    flexDirection: "column",
    backgroundColor: colors.grey,
    padding: 12,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginBottom: 5,
    marginRight: 10
  },
  subView: {
    //width: 'auto',
    alignSelf: 'flex-start',
    margin: 5,
    //backgroundColor: colors.greyD
  },
  subItem: {
    flexDirection: "column",
    alignContent: "flex-start",
    //flex: 1,

  },
  button: {
    alignContent: "flex-start",
    backgroundColor: "red",
    padding: 12,
    paddingHorizontal: 15,
    borderRadius: 50,
    marginBottom: 5,
    marginRight: 10

  },

  list: {
    margin: 30,
  },
  total: {
    alignSelf: 'center',
  },
  empty: {
    alignSelf: 'center',
    color: colors.red,
  },
  bottomMenu: {
    flex: 1,
    alignSelf: "flex-end",
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    margin: 20,
  },


  buttonDel: {
    width: 50,
    height: 50,
    margin: 5,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.red
  },
  logo: {
    color: colors.white,
  },
  text: {
    paddingTop: 10,
    color: colors.white,
    fontSize: 20,
    textAlign: "center"
  },
});

export default styles;