import React, { useContext } from 'react';
import { NavigationContainer } from '@react-navigation/native';

import EventProvider from '../context/Event.context';
import { AuthContext } from '../context/Auth.context';
import { linking } from '../App';
import EventRoute from './Event.routes';
import AuthRoute from './Auth.routes';


const IndexRoute: React.FC = () => {
  const authContext = useContext(AuthContext);

  return (
    <NavigationContainer linking={linking}>
      {authContext.IsAuthenticated() ?
        <EventProvider>
          <EventRoute />
        </EventProvider>
        :
        <AuthRoute />
      }
    </NavigationContainer>
  )
};

export default IndexRoute;
