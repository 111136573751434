import { StyleSheet } from "react-native";
import colors from "../../config/colors";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: colors.white,
    paddingTop: 20,
    color: colors.black
  },

  //FORM

  containerForm: {
    backgroundColor: colors.grey,
    alignSelf: "stretch",
    padding: 12,
    margin: 10,
    borderRadius: 5
  },

  // LIST

  containerList:{
    flex: 1,
    alignSelf: "stretch",
  },
  itemText: {
    width: "100%",
    alignContent: "flex-start",
    backgroundColor: colors.grey,
    padding: 12,
    paddingHorizontal: 20,
    borderRadius: 50,
    margin: 10,
    marginBottom:5,
    marginRight:20
  },



  
  buttonContainer:{
    width: "100%",
    marginTop: 20
  },
  buttonAdd: {
    width:60,
    height:60,
    position: "absolute",
    bottom: 30,
    left: 20,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primary
  },
  buttonAddIcon: {
    color: colors.white,
    fontSize: 20
  },
  buttonDel: {
    alignContent: "flex-start",
    backgroundColor: colors.primary,
    padding: 12,
    paddingHorizontal: 15,
    borderRadius: 50,
    marginBottom:5,
    marginRight:10

  },

  deleteTask: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 5
  },


  // MODAL

  modalContainer:{
    flex: 1,
    justifyContent: "flex-end",
    marginHorizontal: 20
  },

  modalContent: {
    backgroundColor: colors.grey,
    alignItems: "center",
    padding: 20,
    width: "100%",
    alignSelf: "center",
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    //height: "40%"
  },






  infoContainer:{
    flex: 1
  },
  emptyContainer:{
    alignItems: "center",
    justifyContent: "center",
    marginTop: 200,
    opacity: 0.5
  },
    
  input:{
    backgroundColor: "#fff",
    padding: 10,
    fontSize: 15,
    width: "100%",
    alignSelf: "center",
    marginTop: 5,
    marginBottom: 20
  },
  button:{
    backgroundColor: colors.primary,
    padding: 10,
    width: "30%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20
  },
  buttonText:{
    fontSize: 17,
    color: colors.white,
    
  }

});

export default styles;

export const stylesDropdown = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputWeb: {
    fontSize: 15,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0,
    // borderColor: 'purple',
    // borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
    
    backgroundColor: "#fff",
    padding: 10,
    width: "100%",
    alignSelf: "center",
    marginTop: 5,
    marginBottom: 20
  },
});
