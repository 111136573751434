import { collection, doc, deleteDoc, getDocs, query, where } from "firebase/firestore";
import db from "../config/Firebase";
import IReview from "../interfaces/Review.interface";
import { GetClubs } from "./Club.service";
import IEvent from "../interfaces/Event.interface";
import ITask from "../interfaces/task.interface";
import IRanking from "../interfaces/Ranking.interface";
import IClub from "../interfaces/Club.interface";

interface ITaskPerform {
  club: IClub,
  task: ITask,
  review?: IReview,
  performed: boolean
}

export async function GetRankingExtract(event: IEvent): Promise<any[]> {
  let tasks: ITask[] = await getTasks(event.id);
  let reviews: IReview[] = [];
  if (tasks) {
    let idList: string[] = []
    tasks.forEach((t) => { idList.push(t.id!); });
    reviews = await getReviews(idList);
  }
  let clubs = await GetClubs(event.conference);
  let ranking: IRanking[] = [];

  clubs.forEach((club) => {
    let task: ITask;
    let r: IRanking = {
      club: club,
      reviews: [],
      points: 0
    }
    let revList: IReview[] = reviews.filter((r) => r.clubId == club.id!);
    revList.forEach(rev => {
      rev.task = tasks.find(t => t.id == rev.taskId)!;
      r.reviews.push(rev);
      if (rev.value > 0) {
        r.points = r.points + (rev.value ?? 0);
      } else {
        r.points = r.points + (rev.ratio / 100 * (rev.task!.points ?? 0));
      }
    });
    ranking.push(r);
  });
  return ranking.sort((a, b) => (a.points < b.points) ? 1 : (a.points > b.points) ? -1 : 0);
}


async function getTasks(eventId: string): Promise<ITask[]> {
  let colRef = collection(db, 'tasks');
  let revList = await getDocs(query(colRef, where("eventId", "==", eventId)));

  let list: any = [];
  revList.forEach((doc) => {
    list.push({ id: doc.id, ...doc.data() });
  });

  return list;
}

async function getReviews(taskIds: string[]): Promise<IReview[]> {
  let colRef = collection(db, 'reviews');
  let split: any[] = [];
  let revList: IReview[] = [];

  while (taskIds.length > 0) {
    split.push(taskIds.splice(0, 10));
  }

  split.forEach(async element => {
    let result = await getDocs(query(colRef, where("taskId", "in", element)));
    result.forEach((doc) => {
      let r: IReview = {
        id: doc.id,
        email: doc.data().email,
        taskId: doc.data().taskId,
        clubId: doc.data().clubId,
        ratio: doc.data().ratio,
        value: doc.data().value,
        timestamp: doc.data().timestamp
      };
      revList.push(r);
    })
  });

  return revList;
}

export async function DeleteReview(reviewId: string) {
  console.log("deleting", reviewId);
  await deleteDoc(doc(db, "reviews", reviewId));
}
