import React, { createContext, useState } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import GetToken from "../services/Auth.service";
import ILogin from "../interfaces/Login.interface";

const KEY = 'TOKEN';

interface AuthContextData {
  authToken: string;
  IsAuthenticated: () => boolean;
  Login: (credentials: ILogin) => Promise<void>;
  Logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextData>({
  authToken: '',
  IsAuthenticated: (): boolean => { return false },
  Login: async (credentials: ILogin) => { },
  Logout: async () => { },
} as AuthContextData);

export default function AuthProvider({ children }) {
  const [authToken, setAuthToken] = useState('');

  AsyncStorage.getItem(KEY).then((value) => {
    setAuthToken(value);
  });

  function IsAuthenticated() {
    return !!authToken;
  }

  async function Login(credentials: ILogin) {
    const token = await GetToken(credentials);
    if (token) {
      setAuthToken(token);
      await AsyncStorage.setItem(KEY, token);
    }
  }

  async function Logout() {
    setAuthToken(null);
    await AsyncStorage.removeItem(KEY);
  }

  const data = {
    authToken,
    IsAuthenticated,
    Login,
    Logout,
  }

  return (
    <AuthContext.Provider value={data} >
      {children}
    </AuthContext.Provider>
  );
};
