import IClub from "../interfaces/Club.interface";

export function SortClubByName(list: IClub[]): IClub[] {
  const final = list.sort((a, b) => compareClubsByName(a, b));
  return final;
}

function compareClubsByName(clubA: IClub, clubB: IClub): number {
  var nameA = clubA.name!.toLowerCase();
  var nameB = clubB.name!.toLowerCase();
  return nameA.localeCompare(nameB);
}

export function compareByName(A: string, B: string): number {
  var nameA = A.toLowerCase();
  var nameB = B.toLowerCase();
  return nameA.localeCompare(nameB);
}
