import React, { useContext, useEffect, useState } from 'react';
import { FlatList, Modal, Platform, Pressable, Text, TextInput, ToastAndroid, TouchableOpacity, View } from 'react-native';
import { EvilIcons, FontAwesome } from "@expo/vector-icons";
import { StatusBar } from 'expo-status-bar';
import RNPickerSelect from 'react-native-picker-select';

import styles, { stylesDropdown } from './style';
import ITask, { ISubtask } from '../../interfaces/task.interface';
import { EventContext } from '../../context/Event.context';
import { GetTasks, AddTask, DeleteTask, SaveTask } from '../../services/Task.service';
import AddButton from '../../components/AddButton';
import colors from '../../config/colors';
import { compareByName } from '../../services/Utils.service';


export default function Tasks() {
  const eventContext = useContext(EventContext);
  const conferenceId = eventContext.event?.conference ?? '';
  const eventId = eventContext.event?.id ?? '';
  const cleanTask: ITask = {title: "", category: "", points: 100, manual: false, type: "simple", eventId: eventId};
  const types = [{value: 'simple', label:"Simples"},{value:'subtasks', label: "Sub-Itens"}];

  const [tasks, setTasks] = useState<ITask[]>([]);
  const [task, setTask] = useState<ITask>(cleanTask);

  const [newPoints, setNewPoints] = useState<string>(cleanTask.points.toString());
  const [newSubTasks, setNewSubTasks] = useState<ISubtask[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selected, setSelected] = React.useState("simple");

  

  GetTasks(eventId).then((taskList) => {
    setTasks(taskList.sort((a, b) => compareByName(a.title, b.title)));
  });

  function editTask(taskId: string) {
    const task = tasks.find(x => x.id == taskId);
    if(task){
      setTask(task);
      openModal();
    }
  }
  
  // get entire list
  async function getTaskList() {
    let list = await GetTasks(eventId);
    setTasks(list);
  }

  // delete entire list
  async function deleteTask(taskId: string) {
    try {
      DeleteTask(taskId);

      if(Platform.OS === "android"){
        ToastAndroid.show("Atividade apagada", ToastAndroid.SHORT);
      }
    } catch (error) {
      
    }
    setTask(cleanTask);
    await getTaskList();
}

  // update item
  async function saveTask() {
    try {
      if(task.id){
        await SaveTask(task);
      }else{
        await AddTask(task);
      }

      if(Platform.OS === "android"){
        ToastAndroid.show(`${task.title} foi salvo`, ToastAndroid.SHORT);
      }
      getTaskList();
      setModalVisible(false);
      setTask(cleanTask);
    } catch (e) {
      console.error("Error saving document: ", e);
    }
  }

  function openModal() {
    setModalVisible(true);
  }
  
  function openCleanModal() {
    setTask(cleanTask);
    setModalVisible(true);
  }

  function changePoints(text: string) {
    let txt = onlyNumbers(text);
    setNewPoints(txt);
    setTask({ ...task, points: parseInt(txt) } as ITask);

  }

  function onlyNumbers(text:string): string {
    let newText = '';
    let numbers = '0123456789';

    for (var i=0; i < text.length; i++) {
        if(numbers.indexOf(text[i]) > -1 ) {
            newText = newText + text[i];
        }
        else {
            // your call back function
            alert("please enter numbers only");
        }
    }
    return newText;
  }

  function changeType(text: string) {
    setSelected(text);
    setTask({ ...task, type: text} as ITask);
  }



  return (
    <View style={[
      styles.container,
      {
        opacity: modalVisible ? 0.9 : 1,
        backgroundColor: modalVisible ? colors.black : colors.white
      }
    ]}>
      <View style={{
        opacity: modalVisible ? 0.1 : 1
      }}>

      <StatusBar style="light" />
      <View style={styles.infoContainer}>
        <View style={styles.containerForm}>
          <Text>Id: {task.id}</Text>
          <Text>Título: {task.title}</Text>
          <Text>Categoria: {task.category}</Text>
          <Text>Pontos: {task.points}</Text>
        </View>
        <Text>Quantidade: {tasks.length}</Text>
        <FlatList
          style={styles.containerList}
          showsVerticalScrollIndicator={false}
          data={tasks}
          renderItem={
            ({ item }) =>
              <View style={styles.deleteTask}>
                <Text style={styles.itemText} onPress={() => editTask(item.id!)}>{item.title}</Text>
                <TouchableOpacity style={styles.buttonDel} onPress={() => deleteTask(item.id!)}>
                  <FontAwesome name="trash" size={23} color="white" />
                </TouchableOpacity>
              </View>
          }
          keyExtractor={(item, index) => index.toString()}
        />
      </View>

      </View>

      {/* modal for new item */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            {/* close modal button */}
            <Pressable style={{alignSelf: "flex-end",alignItems: "center",justifyContent: "center"}} onPress={()=>setModalVisible(false)}>
              <EvilIcons name="close-o" size={30} color={colors.primary} />
            </Pressable>
            
            {/* textinput for new item */}
            <Text>Título: </Text>
            <TextInput
              placeholder='Título'
              value={task.title}
              onChangeText={(text) => setTask({ ...task, title: text } as ITask)}
              //onSubmitEditing={addTask}
              style={styles.input}
              autoFocus
            />
            <Text>Categoria: </Text>
            <TextInput
              placeholder='Categoria'
              value={task.category}
              onChangeText={(text) => setTask({ ...task, category: text } as ITask)}
              //onSubmitEditing={addTask}
              style={styles.input}
            />
            <Text>Pontos: </Text>
            <TextInput
              placeholder='Pontos'
              value={newPoints}
              keyboardType="number-pad"
              onChangeText={(text)=> changePoints(text)}
              //onSubmitEditing={addTask}
              style={styles.input}
            />
            <Text>Tipo: </Text>
            <RNPickerSelect
              onValueChange={(value) => {alert(value); changeType(value)} }
              items={types}
              style={stylesDropdown}
              value={selected}
            />
            {/* add button */}
            <Pressable style={styles.button} onPress={saveTask}>
              <Text style={styles.buttonText}>{task.id ? "Salvar" : "Adicionar"}</Text>
            </Pressable>
          </View>
          
        </View>
      </Modal>

      {/* button to open the modal */}
      <View style={styles.buttonContainer}>
        <AddButton onPress={openCleanModal} />
      </View>
    </View>
  )


}
