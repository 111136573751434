import React from "react";
import { Pressable, StyleSheet, Text } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import colors from "../config/colors";

export interface IAddButton {
  onPress: () => void;
  text?: string | undefined;
  color?: string | undefined;
  icon?: string | undefined;
}

export default function AddButton(props: IAddButton) {
  return (
    <Pressable style={styles.container} onPress={props.onPress}>
      <FontAwesome name={"plus"} size={24} color={colors.white} />
      <Text style={styles.title}>{props.text! ?? "Cadastrar"}</Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container:{
    backgroundColor: colors.primary,
    width: "90%",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: 10,
    borderRadius: 10,
    marginBottom: 20
  },
  title:{
    color: colors.white,
    fontSize: 20,
    marginLeft: 10
  }
})