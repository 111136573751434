import React, { useContext, useEffect, useState } from "react";
import { FlatList, Platform, Text, ToastAndroid, View } from "react-native";
import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

import { EventContext } from "../../context/Event.context";
import Page from '../../components/Page';
import { GetRankingExtract, DeleteReview } from "../../services/Ranking.service";
import IRanking from "../../interfaces/Ranking.interface";
import ITask from "../../interfaces/Task.interface";
import { GetTasks } from "../../services/Task.service";
import { compareByName } from "../../services/Utils.service";
import styles from "./ClubsByTasks.styles";


export default function ClubsByTasks() {
  const eventContext = useContext(EventContext);
  const [ranking, setRanking] = useState<IRanking[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tasks,setTasks] = useState<ITask[]>([]);
  const [detailed, setDetailed] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    GetTasks(eventContext.event!.id).then((list) => setTasks(list));
    GetRankingExtract(eventContext.event!).then((value) => {
      console.log("Ranking:", value);
      setRanking(value.sort((a: any, b: any) => (a.Pontos > b.Pontos) ? 1 : (a.Pontos < b.Pontos) ? -1 : 0));
      setIsLoading(false);
    });
  }, [])

  function empty() {
    if (ranking && ranking.length == 0) {
      return (<Text style={styles.empty}>Não existe Ranking.</Text>)
    }
  }

  function loading() {
    if (isLoading) {
      return (<Text style={styles.empty}>Carregando...</Text>)
    }
  }

  function deleteReview(reviewId: string) {
    console.log("Alert", reviewId);

    try {
      DeleteReview(reviewId);

      if(Platform.OS === "android"){
        ToastAndroid.show("Atividade apagada", ToastAndroid.SHORT);
      }

      let list: IRanking[] = []
      ranking.forEach(r => {
        let revList = r.reviews.filter(x => x.id != reviewId);
        r.reviews = revList;
        let points = 0;
        revList.forEach(x => x.value == 0 ? points += x.ratio : points += x.value);
        list.push(r);
      });
      setRanking(list);
    } catch (error) {
      
    }
  }

  return (
    <Page title="Clubes por Atividades">
      <View style={styles.list}>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={tasks}
          renderItem={
            ({ item }) =>
              <View style={styles.view}>
                <Text style={styles.item}>
                  <View style={styles.head}>
                    <Text>{item.title ?? ""} - {item.points ?? 0} pontos</Text>
                  </View>

                  {ranking.filter(x => x.reviews.filter(y => y.taskId == item.id).length > 0)
                          .sort((a, b) => compareByName(a.club.name!, b.club.name!))
                          .map((rank) => (
                    <View style={styles.subView}>
                      <Text style={styles.subItem}>{rank.club.name! ?? ""}</Text>
                    </View>
                  ))}
                </Text>
              </View>
          }
          keyExtractor={(item, index) => item.id!}
        />
        {isLoading ? loading() : empty()}
      </View>

      <Text style={styles.total}>Total: {ranking?.length ?? 0} clubes</Text>

    </Page>
  )
}
