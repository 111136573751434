import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDocs, initializeFirestore, query, collection, writeBatch, doc, getDoc, addDoc, setDoc } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_RECAPTCHA_KEY,
} from '@env';
import "firebase/storage";
import * as crypto from "crypto";

import IClub from "../interfaces/Club.interface";
import ITask from "../interfaces/Task.interface";

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY ?? "AIzaSyASZph7QoDnsNefNPyrlKOiHtEtqcHq8cM",
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN ?? "dbv-ranking.firebaseapp.com",
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL ?? "https://dbv-ranking-default-rtdb.firebaseio.com",
  projectId: REACT_APP_FIREBASE_PROJECT_ID ?? "dbv-ranking",
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET ?? "dbv-ranking.appspot.com",
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? "826962621713",
  appId: REACT_APP_FIREBASE_APP_ID ?? "1:826962621713:web:9dd66d5ff87c7b1dd308b0",
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID ?? "G-NZJ4E8KXC8"
};

const app = initializeApp(firebaseConfig);
let key: string = REACT_APP_RECAPTCHA_KEY ?? "6LfxctkiAAAAAE-NjxNDFt7f4ps1yWuS2eQjpOgO";
//console.log("Recapcha Key: ", key);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(key),
  isTokenAutoRefreshEnabled: true,
});
const db = initializeFirestore(appCheck.app, { experimentalAutoDetectLongPolling: true });
const auth = getAuth(appCheck.app);

async function getData(collectionName: string) {
  console.log("inicio");
  let col = collection(db, collectionName);
  console.log(col);
  let snapshot = await getDocs(query(col));
  console.log(snapshot);
  let list: any[] = [];
  snapshot.forEach((doc) => {
    list.push({ id: doc.id, ...doc.data() });
  });
  return list;
}

//async function saveClubsToCloud(conference: string, clubs: IClub[]) {
async function saveClubsToCloud() {
  /*
  let conference: string = 'APaC';
  let type: string = 'DBV';
  let clubs: IClub[] = clubesAPaC();
  console.log("Conference: ", conference);
  console.log("Qtd Clubes: ", clubs.length);

  // Get a new write batch
  const batch = writeBatch(db);

  let confRef = doc(db, 'conferences', conference); 
  let clubsRef = collection(confRef, 'clubs');
  let qtdChar = 20 - conference.length - type.length - 2;
  console.log("Qtd Char: ", qtdChar);

  clubs.forEach(async (club: IClub) => {
    let random = crypto.randomBytes(qtdChar).toString('hex');
    let id = conference + '_' + type + '_' + random.substring(0,qtdChar);

    //let ref = await addDoc(clubsRef, club);
    const docRef = doc(clubsRef, id);
    
    await setDoc(docRef, club, { merge: true });
    console.log("id: " + docRef.id + " - Clube: " + club.name);
  });
  console.log("Importação efetuaca com sucesso!");
  console.log(clubs.length + " enviados");
  */
}

async function saveTasksToCloud() {
  /*
  let conference: string = 'APaC';
  let tasks: ITask[] = tasksAPaC();
  console.log("Conference: ", conference);
  console.log("Qtd Clubes: ", tasks.length);

  // Get a new write batch
  const batch = writeBatch(db);

  //let confRef = doc(db, 'conferences', conference);
  let tasksRef = collection(db, 'tasks');
  let qtdChar = 20 - conference.length;

  tasks.forEach(async (task: ITask) => {
    let random = crypto.randomBytes(qtdChar).toString('hex');
    let id = conference + random;

    //let ref = await addDoc(tasksRef, task);
    const docRef = await doc(tasksRef, 'test', id);
    setDoc(docRef, task, { merge: true });
    console.log("id: " + docRef.id + " - Tarefa: " + task.title);
  });
  console.log("Importação efetuaca com sucesso!");
  console.log(tasks.length + " enviados");
  */
}

export default db;
export { getData, saveClubsToCloud, saveTasksToCloud, auth };
