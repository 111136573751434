import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
    color: "#000"
  },

  itemText: {
    width: "100%",
    alignContent: "flex-start",
    backgroundColor: "#f5f5f5",
    padding: 12,
    paddingHorizontal: 20,
    borderRadius: 50,
    marginBottom: 5,
    marginRight: 10
  },

  buttonAdd: {
    width: 60,
    height: 60,
    position: "absolute",
    bottom: 30,
    left: 20,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f92e6a"
  },
  buttonAddIcon: {
    color: "#fff",
    fontSize: 20
  },
});

export default styles;