import React, { useContext, useEffect, useState } from "react";
import { FlatList, StyleSheet, Text, View } from "react-native";

import colors from "../../config/colors";
import { EventContext } from "../../context/Event.context";
import Page from '../../components/Page';
import { GetRankingExtract } from "../../services/Ranking.service";
import IRanking from "../../interfaces/Ranking.interface";


export default function RankingSummarized() {
  const eventContext = useContext(EventContext);
  const [ranking, setRanking] = useState<IRanking[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    GetRankingExtract(eventContext.event!).then((value) => {
      console.log("Ranking:", value);
      setRanking(value.sort((a: any, b: any) => (a.Pontos > b.Pontos) ? 1 : (a.Pontos < b.Pontos) ? -1 : 0));
      setIsLoading(false);
    });
  }, [])

  function empty() {
    if (ranking && ranking.length == 0) {
      return (<Text style={styles.empty}>Não existe Ranking.</Text>)
    }
  }

  function loading() {
    if (isLoading) {
      return (<Text style={styles.empty}>Carregando...</Text>)
    }
  }

  return (
    <Page title="Clubes">
      <View style={styles.list}>
        <Text style={styles.item}>
          <Text style={styles.total}>Total</Text>
          <Text style={styles.text}> Nome do Clube</Text>
        </Text>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={ranking}
          renderItem={
            ({ item }) =>
              <Text style={styles.item}>
                <Text style={styles.total}>{Math['ceil'](item.points)}</Text>
                <Text style={styles.text}>{item.club.name ?? ""}</Text>
              </Text>
          }
          keyExtractor={(item, index) => index.toString()}
        />
        {isLoading ? loading() : empty()}
      </View>

      <Text style={styles.total}>Total: {ranking?.length ?? 0} clubes</Text>

    </Page>
  )
}

const styles = StyleSheet.create({
  view: {
    width: 'auto',
    position: 'relative',
    flexDirection: "row",
    alignSelf: 'stretch',
    justifyContent: "flex-start",
    margin: 5
  },
  head: {
    width: "100%",
    position: 'relative',
    margin: 5
  },
  item: {
    width: '100%',
    flexDirection: "row",
    paddingHorizontal: 20,
    borderRadius: 50,
    marginBottom: 5,
    marginRight: 10
  },
  text: {
    minWidth: "250px",
    paddingHorizontal: 20
  },
  total: {
    width: "150px",
  },
  subView: {
    position: 'relative',
    margin: 5
  },
  subItem: {
    alignContent: "flex-start",
    flex: 1,

  },
  button: {
    alignContent: "flex-start",
    backgroundColor: "red",
    padding: 12,
    paddingHorizontal: 15,
    borderRadius: 50,
    marginBottom: 5,
    marginRight: 10
  },

  list: {
    margin: 30,
  },
  empty: {
    alignSelf: 'center',
    color: colors.red,
  },
  bottomMenu: {
    flex: 1,
    alignSelf: "flex-end",
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    margin: 20,
  },
});

