import React from 'react';
import { FlatList, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { FontAwesome } from "@expo/vector-icons";

import colors from "../config/colors";
import Title from './Title';

export default function List(props) {
  const navigation = useNavigation();
  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      data={props.data}
      renderItem={
        ({ item }) =>
          <View style={styles.view}>
            <Text style={styles.item} onPress={() => navigation.navigate(props.pageRedirect, { data: item })}>{item.name} - {item.region}</Text>
            <TouchableOpacity style={styles.button} onPress={() => deleteTask(item.id)}>
              <FontAwesome name={props.icon} size={23} color="white" />
            </TouchableOpacity>
          </View>
      }
      keyExtractor={(item, index) => index.toString()}
    />
  );
}

const styles = StyleSheet.create({
  view: {
    width: 'auto',
    position: 'relative',
    flexDirection: "row",
    alignSelf: 'stretch',
    justifyContent: "flex-start",
    margin: 5
  },
  item: {
    width: '100%',
    backgroundColor: colors.grey,
    padding: 12,
    paddingHorizontal: 20,
    borderRadius: 50,
    marginBottom: 5,
    marginRight: 10
  },
  button: {
    alignContent: "flex-start",
    backgroundColor: "red",
    padding: 12,
    paddingHorizontal: 15,
    borderRadius: 50,
    marginBottom: 5,
    marginRight: 10

  },
});

