import React from 'react';
import { StyleSheet, Text, Image } from 'react-native';

import colors from "../config/colors";

export default function Logo() {
  return (
    <>
      <Image style={styles.image} source={require('../assets/icon.png')} />
      <Text style={styles.title}>CamporiOnline.org</Text>
    </>
  );
}

const styles = StyleSheet.create({
  image: {
    alignSelf: "center",
    width: 200,
    height: 200,
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.black,
  },
});

